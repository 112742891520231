import React, { useEffect, useState } from 'react';

import { Checkbox, Radio, Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { TFF } from '@tff/types';
import { MissingAncillaries } from './Utils/AncillariesPage/missingAncillaries';
import { AncillaryType } from '../../types/ancillaryTypes';
import { ValidatedSsrDetails } from '@tff/types/TFF';

interface props {
  passenger: TFF.Passenger;
  ancillary: TFF.MetaValue;
  type: string;
  toggleChecked: (passenger: TFF.Passenger, code: string, airlineCode?: string) => boolean;
  handleRadioChange: (checked: boolean, passenger: TFF.Passenger, code: string, airlineCode?: string) => void;
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    passenger: TFF.Passenger,
    code: string,
    airlineCode?: string,
    price?: number,
  ) => void;
  updateSectionPrice: (price: number) => void;
  flightOnd: string;
  notApplyForAllFlights: boolean;
  rebooking?: boolean;
  rebookOffers?: any;
  missingAncillaries: MissingAncillaries;
  validatedAncillaries?: ValidatedSsrDetails[];
  ssrAvailability?: Record<string, number>;
  isTuiRelated?: boolean;
  isFlightRebooked: boolean | undefined;
}

const FlightAncillaryTableCell: React.FC<props> = ({
  updateSectionPrice,
  handleRadioChange,
  toggleChecked,
  handleCheckboxChange,
  ancillary,
  type,
  passenger,
  flightOnd,
  notApplyForAllFlights,
  rebooking,
  missingAncillaries,
  validatedAncillaries,
  ssrAvailability,
  isTuiRelated,
  isFlightRebooked,
}) => {
  const existingAncillaries = passenger.JourneyExtras?.flatMap(i => (Array.isArray(i.Ssrs) ? i.Ssrs : [])).map(str =>
    str.substring(0, str.lastIndexOf('~')),
  );
  const [checked, setChecked] = useState(toggleChecked(passenger, ancillary.airlineSsrCode));

  const handleCheckboxChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleCheckboxChange(event, passenger, ancillary.code, ancillary.airlineSsrCode, ancillary.price.amount);
  };

  const handleRadioChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRadioChange(checked, passenger, ancillary.code, ancillary.airlineSsrCode);
  };

  useEffect(() => {
    setChecked(toggleChecked(passenger, ancillary.code));
  }, [toggleChecked(passenger, ancillary.code)]);

  useEffect(() => {
    let updateSum: number = ancillary.price.amount;
    if (!notApplyForAllFlights) {
      updateSum *= 2;
    }
    if (!existingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${ancillary.airlineSsrCode}`)) {
      if (ancillary.code !== 'BA0') {
        if (checked) {
          updateSectionPrice(updateSum);
        } else {
          updateSectionPrice(-updateSum);
        }
      }
    }
  }, [checked]);

  const isDisabled = (): boolean => {
    if (isTuiRelated === false) {
      return !isFlightRebooked;
    }
    if (ancillary.type === AncillaryType.Sports && !checked) {
      return (
        (ssrAvailability && ssrAvailability[ancillary.code] <= 0) ||
        [...missingAncillaries.inbound, ...missingAncillaries.outbound].some(
          missingAncillary =>
            missingAncillary.JourneyOnd === flightOnd &&
            missingAncillary.PaxID !== passenger.Id &&
            missingAncillary.Type === ancillary.airlineSsrCode,
        )
      );
    }

    return false;
  };
  const findValidatedSsr = () => {
    const validatedSSr = validatedAncillaries?.find(a => {
      return a.code === ancillary.code && a.paxId === passenger.Id && a.segmentOnd === flightOnd;
    });
    return validatedSSr;
  };

  const getColor = (): 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default' => {
    let color =
      existingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${ancillary.airlineSsrCode}`) && !rebooking
        ? 'success'
        : 'primary';

    if (validatedAncillaries && validatedAncillaries.length > 0) {
      const validatedSSr = findValidatedSsr();
      if (validatedSSr?.status && validatedSSr.status !== 'AVAILABLE') {
        color = 'error';
      }
    }
    return color as 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';
  };

  return (
    <TableCell key={passenger.Id} align="center">
      {type === 'BAGGAGE' ? (
        <Radio checked={checked} onChange={handleRadioChangeWrapper} color={getColor()} />
      ) : (
        <Tooltip
          title={isTuiRelated === false && !isFlightRebooked ? 'Non-TUI related' : ''}
          disableHoverListener={false}
          disableFocusListener={false}
        >
          <span>
            <Checkbox
              checked={checked}
              disabled={isDisabled()}
              onChange={handleCheckboxChangeWrapper}
              color={getColor()}
            />
          </span>
        </Tooltip>
      )}
    </TableCell>
  );
};

export default FlightAncillaryTableCell;
