import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { TFF } from '@tff/types';
import { PlusIcon } from '../icons/plusIcon';
import { MinusIcon } from '../icons/minusIcon';
import { ClickableMinusIcon } from '../icons/ClickableMinusIcon';
interface props {
  passenger: TFF.Passenger;
  ancillary: TFF.MetaValue;
  ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] };
  updateSectionPrice: (price: number) => void;
  notApplyForAllFlights: boolean;
  flightOnd: string;
  addSsrsToAdd: (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs) => void;
  removeSsrsToAdd: (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd) => void;
  setLuggagePrice: React.Dispatch<React.SetStateAction<{ [flightId: string]: { [passengerId: string]: number } }>>;
  luggagePrice: { [flightId: string]: { [passengerId: string]: number } };
  ancillaryArray: any[];
  setSsrsToAdd: React.Dispatch<React.SetStateAction<{ [paxId: string]: TFF.SsrDetails[] }>>;
}

const LuggageTableCell: React.FC<props> = ({
  updateSectionPrice,
  ancillary,
  passenger,
  notApplyForAllFlights,
  ssrsToAdd,
  flightOnd,
  addSsrsToAdd,
  removeSsrsToAdd,
  setLuggagePrice,
  luggagePrice,
  ancillaryArray,
  setSsrsToAdd,
}) => {
  const luggageCount =
    ssrsToAdd[passenger.Id].find(ssr => ssr.segmentOnd === flightOnd && ssr.code === ancillary.code)?.amount || 0;

  const [includedSsrs] = useState<{ [paxId: string]: TFF.SsrDetails[] }>(ssrsToAdd);

  const [isLuggageDisabled, setIsLuggageDisabled] = useState<boolean>(false);

  const updatePrice = (flightId: string, passengerId: string, price: number) => {
    setLuggagePrice(prevState => {
      const currentPrice = prevState[flightId]?.[passengerId] || 0;
      return {
        ...prevState,
        [flightId]: {
          ...(prevState[flightId] || {}),
          [passengerId]: currentPrice + price,
        },
      };
    });
  };

  const resetPriceForPassenger = (flightId: string, passengerId: string) => {
    setLuggagePrice(prevState => {
      if (!prevState[flightId]) return prevState;

      const newState = { ...prevState };
      newState[flightId] = { ...newState[flightId] };
      newState[flightId][passengerId] = 0;

      return newState;
    });
  };

  const processSsrsToAdd = (ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] }, removeSsrsToAdd, flightOND, pax) => {
    ssrsToAdd[pax]
      .filter(ssr => ssr.code.includes('BX') && ssr.segmentOnd === flightOND)
      .forEach(ssr => {
        removeSsrsToAdd(
          pax,
          {
            segmentOnd: flightOND,
            code: ssr.code,
            amount: 1,
          },
          setSsrsToAdd,
        );
      });
  };

  const priceDivide = ancillaryArray.filter(i => i.type !== 'BAGGAGE').length;

  const ssrDetails: TFF.SsrDetails = {
    code: ancillary.code,
    amount: 1,
    segmentOnd: flightOnd,
  };

  useEffect(() => {
    setIsLuggageDisabled(
      ssrsToAdd[passenger.Id].filter(i => i.code === 'BA0' && i.segmentOnd === flightOnd).length > 0,
    );
  }, [ssrsToAdd]);

  useEffect(() => {
    if (isLuggageDisabled && luggagePrice?.[flightOnd]?.[passenger.Id]) {
      const itemPrice = luggagePrice[flightOnd]?.[passenger.Id] ?? 0;
      updateSectionPrice(-itemPrice / priceDivide || 0);
      resetPriceForPassenger(flightOnd, passenger.Id);
      processSsrsToAdd(ssrsToAdd, removeSsrsToAdd, flightOnd, passenger.Id);
      if (!notApplyForAllFlights) {
        const itemPrice = luggagePrice[flightOnd.split('-').reverse().join('-')]?.[passenger.Id] ?? 0;
        updateSectionPrice(-itemPrice / priceDivide || 0);
        resetPriceForPassenger(flightOnd.split('-').reverse().join('-'), passenger.Id);
        processSsrsToAdd(ssrsToAdd, removeSsrsToAdd, flightOnd.split('-').reverse().join('-'), passenger.Id);
      }
    }
  }, [isLuggageDisabled]);

  const removeLuggage = (price: number) => {
    if (luggageCount > 0) {
      removeSsrsToAdd(passenger.Id, ssrDetails, setSsrsToAdd);
      if (luggageCount <= includedSsrs[passenger.Id].find(i => i.code === ancillary.airlineSsrCode)?.amount!) {
        addSsrsToAdd(
          passenger.Id,
          {
            code: `${ancillary.code}-remove`,
            amount: 1,
            segmentOnd: flightOnd,
          },
          setSsrsToAdd,
          ssrsToAdd,
          includedSsrs,
        );
      }

      updateSectionPrice(-price);
      updatePrice(flightOnd, passenger.Id, -price);
      if (!notApplyForAllFlights) {
        const ssrDetails2: TFF.SsrDetails = {
          code: ancillary.code,
          amount: 1,
          segmentOnd: flightOnd.split('-').reverse().join('-'),
        };
        removeSsrsToAdd(passenger.Id, ssrDetails2, setSsrsToAdd);
        updateSectionPrice(-price);
        updatePrice(flightOnd.split('-').reverse().join('-'), passenger.Id, -price);
      }
    }
  };

  const addLuggage = (price: number) => {
    addSsrsToAdd(passenger.Id, ssrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs);

    if (luggageCount <= includedSsrs[passenger.Id].find(i => i.code === ancillary.airlineSsrCode)?.amount!) {
      removeSsrsToAdd(
        passenger.Id,
        {
          code: `${ancillary.code}-remove`,
          amount: 1,
          segmentOnd: flightOnd,
        },
        setSsrsToAdd,
      );
    }
    updateSectionPrice(price);
    updatePrice(flightOnd, passenger.Id, price);
    if (!notApplyForAllFlights) {
      const ssrDetails2: TFF.SsrDetails = {
        code: ancillary.code,
        amount: 1,
        segmentOnd: flightOnd.split('-').reverse().join('-'),
      };
      addSsrsToAdd(passenger.Id, ssrDetails2, setSsrsToAdd, ssrsToAdd, includedSsrs);
      updateSectionPrice(price);
      updatePrice(flightOnd.split('-').reverse().join('-'), passenger.Id, price);
    }
  };

  return (
    <TableCell
      key={`${passenger.Id}-${flightOnd}`}
      align="center"
      sx={{
        backgroundColor: isLuggageDisabled ? '#F5F5F5' : '',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Button onClick={() => removeLuggage(ancillary.price.amount)} disabled={isLuggageDisabled}>
          {luggageCount === 0 ? <MinusIcon /> : <ClickableMinusIcon />}
        </Button>
        <Typography>{luggageCount}</Typography>
        <Button onClick={() => addLuggage(ancillary.price.amount)} disabled={isLuggageDisabled}>
          <PlusIcon />
        </Button>
      </Box>
    </TableCell>
  );
};

export default LuggageTableCell;
