import React from 'react';
import { TUI_LIGHT } from '../values/style-values';

type AlertVariants = 'default' | 'info' | 'error' | 'success' | 'warning';

interface TFFAlertProps {
  variant: AlertVariants;
  text: string;
  link?: string;
  handleLink?: () => void;
  extraStyles?: React.CSSProperties;
}

export const TFFAlert: React.FC<TFFAlertProps> = ({ variant, text, link, handleLink, extraStyles }) => {
  const iconByVariant = () => {
    switch (variant) {
      case 'info':
        return 'letter-i-circled-filled';
      case 'error':
        return 'exclamation-mark-circled-filled';
      case 'success':
        return 'checkmark-circled-filled';
      case 'warning':
        return 'warning-filled';
      default:
        return 'letter-i-circled-filled';
    }
  };

  const linkElement = link && (
    <button className="link" onClick={handleLink}>
      {link}
    </button>
  );

  return (
    <p className={`alert ${variant}`} role="alert" style={extraStyles}>
      <span className="alert-icon alert-icon-variant">
        <span className={`icon medium ${iconByVariant()}`} data-theme={TUI_LIGHT}></span>
      </span>
      <span className="alert-text">
        {text} {linkElement}
      </span>
    </p>
  );
};

export default TFFAlert;
