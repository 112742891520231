import React from 'react';
import AlternativeFlightPerJourney from './AlternativeFlightPerJourney';
import { AmendJourney } from '@tff/types/TFF';
import AlternativeFlightFares from './AlternativeFlightFares';
import { CombinationOffer } from '@tff/types/TFF/tff-flight-details';

interface AlternativeFlightCardProps {
  outboundJourney: AmendJourney;
  inboundJourney?: AmendJourney;
  fareOffers: CombinationOffer[] | undefined;
  selectedOffer: CombinationOffer | undefined;
  handleOfferClick: (offer: CombinationOffer) => void;
}

const AlternativeFlightCard: React.FC<AlternativeFlightCardProps> = ({
  outboundJourney,
  inboundJourney,
  fareOffers,
  selectedOffer,
  handleOfferClick,
}) => {
  const selectedOfferPjId = Object.keys(selectedOffer?.PriceClasses.Journeys!).join('-');
  const flightCardPjId = `${outboundJourney.Id}-${inboundJourney?.Id}`;

  return (
    <div
      className={`${
        selectedOfferPjId === flightCardPjId ? `tff-alternative-flights-card-selected` : `tff-alternative-flights-card`
      }`}
    >
      <div style={{ width: '50%' }}>
        <AlternativeFlightPerJourney
          journey={outboundJourney}
          isOutbound
          fareOffers={fareOffers || []}
          selectedOffer={selectedOffer}
        />
        {inboundJourney && (
          <AlternativeFlightPerJourney
            journey={inboundJourney}
            fareOffers={fareOffers || []}
            selectedOffer={selectedOffer}
          />
        )}
      </div>
      <div style={{ width: '50%' }}>
        <AlternativeFlightFares
          outboundJourney={outboundJourney}
          inboundJourney={inboundJourney}
          fareOffers={fareOffers || []}
          selectedOffer={selectedOffer}
          handleOfferClick={handleOfferClick}
        />
      </div>
    </div>
  );
};

export default AlternativeFlightCard;
