import React, { JSX, useEffect } from 'react';
import TFFRadioInput from './TFFRadioInput';
import TFFIcon from './TFFIcon';
import TFFTypography from './TFFTypography';

interface TFFRadioAccordionProps {
  inputId: string;
  index: number;
  radioText: string;
  details: JSX.Element;
  handleRadioClick: () => void;
  checked: boolean;
  extraStyles?: React.CSSProperties;
  iconTextStyles?: React.CSSProperties | string;
  iconText?: string | number;
}

export const TFFRadioAccordion: React.FC<TFFRadioAccordionProps> = ({
  inputId,
  index,
  radioText,
  details,
  handleRadioClick,
  checked,
  extraStyles,
  iconText,
  iconTextStyles,
}) => {
  const [open, setOpen] = React.useState<boolean>(checked);

  useEffect(() => {
    setOpen(checked);
  }, [checked]);

  return (
    <article className="accordion" style={extraStyles}>
      <input
        type="checkbox"
        name={`accordion-${inputId}_${index}`}
        id={`accordion-${inputId}_${index}`}
        onChange={() => setOpen(prevState => !prevState)}
        checked={open}
      />
      <label className="header" htmlFor={`accordion-${inputId}_${index}`}>
        <span className="label" style={{ padding: 0 }}>
          <TFFRadioInput
            inputId={`${inputId}_${index}`}
            labelText={radioText}
            handleClick={handleRadioClick}
            checked={checked}
          />
        </span>
        <TFFTypography text={`+${iconText} €`} color={`${iconTextStyles}`} fontWeight="bold" />
        <span aria-label="Control" className="icon-accordion">
          <TFFIcon iconName="chevron-down" />
        </span>
      </label>
      <section className="body">
        <div className="content" style={{ marginLeft: '40px' }}>
          {details}
        </div>
      </section>
    </article>
  );
};

export default TFFRadioAccordion;
