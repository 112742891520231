import React, { useEffect, useMemo, useState } from 'react';
import { PlaneIcon } from '../icons';
import { Box, Typography } from '@mui/material';
import PassengerRow from './PassengerRow';
import { TFF } from '@tff/types';
import { create6MSeatComponentConfig } from '../../util/map6MSeats';
import { Seat6MComponent } from '../../util';
import { AmendBookingRequest, AmendTfaAncillaryLambdaRequest, PaxData } from '@tff/types/TFF';
import { map6MResults } from '../../util/map6MResults';
import { useAmendBooking } from '../../hooks/use-amend-booking';
import SeatsReservation from '../../util/Seats6M';
import CustomDialog from '../Dialogs';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import TFFButton from '../../styled-components/TFFButton';
import TFFTypography from '../../styled-components/TFFTypography';

interface props {
  flightOnd: String;
  isOutbound: boolean;
  passengers: {
    [paxId: string]: TFF.Passenger;
  };
  ancillaries: TFF.Ancillaries;
  orderDetails: TFF.OrderDetails;
  journey: TFF.Journey;
  recordLocator: string;
  tfmPnr?: string;
  midocoOrderNo?: number;
}

const FlightColumn: React.FC<props> = ({
  passengers,
  flightOnd,
  isOutbound,
  ancillaries,
  orderDetails,
  journey,
  recordLocator,
  tfmPnr,
  midocoOrderNo,
}) => {
  const [scope, setScope] = useState<string>('*');
  const [openSeats, setOpenSeats] = useState<boolean>(false);
  const seatsConfig = useMemo(() => create6MSeatComponentConfig([journey], orderDetails), [journey, orderDetails]);
  const { setAmendBookingRequest, amendResponse, amendStatus, amendError } = useAmendBooking();
  const [customDialogMsg, setCustomDialogMsg] = useState<string | undefined>(undefined);
  const [successDialog, setSuccessDialog] = useState<boolean>(false);
  const intl = useIntl();
  const navigate = useNavigate();

  const handleAbordSeatSelection = () => {
    setCustomDialogMsg(undefined);
  };

  const handleConfirmSeatSelection = () => {
    setCustomDialogMsg(undefined);
    handleAmendBooking('confirm', amendResponse!.query!.paxData!, amendResponse!.sessionId!);
  };

  const handleAmendBooking = (action: 'validate' | 'confirm', paxData: PaxData[], sessionId?: string) => {
    const amendBookingRequest: AmendBookingRequest = {
      action: action,
      bookingSource: 'TUI-NSK',
      recordLocator: recordLocator,
      tfmPnr: tfmPnr!,
      paxData: paxData,
      midocoOrderNo: midocoOrderNo,
      sessionId: sessionId,
    };
    setAmendBookingRequest(amendBookingRequest);
  };

  const handleCloseSeatsReservation = (data?: Seat6MComponent) => {
    setOpenSeats(false);
    if (!data) return;
    setScope(prev => (prev === '*' ? '+' : '*'));
    const seatAncillaries = Object.values(ancillaries).filter(
      (a: TFF.Ancillary) => a.JourneyOnd === journey.Ond && a.Type === 'SEAT',
    );
    const mapData: AmendTfaAncillaryLambdaRequest = map6MResults(data.flights, recordLocator, seatAncillaries);
    handleAmendBooking('validate', mapData.paxData, undefined);
  };

  useEffect(() => {
    if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'validate') {
      setCustomDialogMsg(
        `To change your seat, a fee of ${intl.formatNumber(amendResponse.bookingSum?.BalanceDue! as number, { style: 'currency', currency: 'EUR' })} will be applied. Would you like to proceed?`,
      );
      if (amendResponse.tfaAmendResponseDetails?.status === 'BOOKED_PARTIALLY') {
        const message: string = `There is an issue with your seat selection, we can't proceed with the change. Please check your seat selection and try again.
          ${amendResponse.tfaAmendResponseDetails?.issues?.map(i => i.message).join('\n\r')}`;
        setCustomDialogMsg(message);
      }
    } else if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'confirm') {
      setSuccessDialog(true);
    } else if (amendStatus === 'FAILURE') {
      if (amendResponse?.query.action === 'confirm') {
      }
    }
  }, [amendStatus]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '91px',
        minWidth: '320px',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
        }}
      >
        <PlaneIcon
          style={{
            height: 22,
            width: 22,
            transform: isOutbound ? 'rotate(90deg)' : 'rotate(-90deg)',
          }}
        />
        <Typography
          sx={{
            gap: '8px',
            color: 'primary.main',
            fontSize: '18px',
            lineHeight: '20.59px',
            fontWeight: '600',
          }}
        >
          <strong>{flightOnd}</strong>
        </Typography>
        <div style={{ marginLeft: 20, position: 'relative', bottom: 10 }}>
          <TFFButton buttonText="Edit Seats" variant="secondary" handleClick={() => setOpenSeats(true)} size="medium" />
        </div>
      </Box>
      <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {Object.values(passengers).map((passenger, index) =>
          passenger.Id.indexOf('_') > -1 ? (
            <PassengerRow
              key={index}
              passengerNr={parseInt(passenger.Id.split('_')[1]) + 1}
              passenger={passenger}
              ancillaries={ancillaries}
              isOutbound={isOutbound}
            />
          ) : null,
        )}
      </div>
      {openSeats && (
        <div>
          <SeatsReservation
            initialOpen={openSeats}
            onClose={handleCloseSeatsReservation}
            scope={scope}
            configs={seatsConfig}
          />
        </div>
      )}
      {customDialogMsg && (
        <CustomDialog
          initialOpen={true}
          onCancel={() => handleAbordSeatSelection()}
          onConfirm={() => handleConfirmSeatSelection()}
          width="md"
          cancelButtonText={intl.formatMessage({ id: 'abort' })}
          confirmButtonText={intl.formatMessage({ id: 'continue' })}
          closeOnBackdropClick={false}
          title={'Result of adding Seat: ' + amendResponse?.tfaAmendResponseDetails?.status}
          warning
          confirmDisabled={
            !amendResponse?.tfaAmendResponseDetails || amendResponse!.tfaAmendResponseDetails!.status !== 'BOOKED'
          }
        >
          <Typography variant="h6" color="primary">
            {customDialogMsg}
          </Typography>
        </CustomDialog>
      )}
      {successDialog && (
        <CustomDialog
          initialOpen={successDialog}
          width="md"
          closeButton={false}
          confirmButtonText='Reload PNR'
          confirmButtonStyle={{
            backgroundColor: '#F2E30F',
            color: 'black',
          }}
          onConfirm={() => navigate(0)}
          titleBg="#E2F3FE"
          title={<TFFTypography text="Amend Notification" fontWeight="bold" />}
        >
          <TFFTypography color="primary" text="Your seat has been successfully changed." />
        </CustomDialog>
      )}
    </Box>
  );
};

export default FlightColumn;
