import React, { useState } from 'react';
import TFFIcon from './TFFIcon';

interface props {
  accordionId: string;
  summaryChildren: React.ReactNode;
  detailsChildren: React.ReactNode;
  actionsChildren?: React.ReactNode;
}

const TFFAccordionNew: React.FC<props> = ({ accordionId, summaryChildren, detailsChildren, actionsChildren }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(prevState => !prevState);
  };
  return (
    <div
      style={{
        width: '100%',
        border: '1px solid #BABABA',
        borderRadius: '5px',
        margin: '10px',
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        backgroundColor: 'white',
        boxShadow: 'none',
      }}
    >
      <article className="accordion" tabIndex={0}>
        <input
          type="checkbox"
          name={`tui-accordion-for-${accordionId}`}
          id={`tui-accordion-for-${accordionId}`}
          tabIndex={-1}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label className="header" htmlFor={`tui-accordion-for-${accordionId}`} style={{ padding: '8px 16px' }}>
          <span className="label">{summaryChildren}</span>
          <span aria-label="Control" className="icon-accordion">
            <TFFIcon iconName="chevron-down" color="color-functional-base-600" />
          </span>
        </label>
        <section className="body" style={{ backgroundColor: '#E2F3FE', maxHeight: isChecked ? '100%' : '0vh' }}>
          <div className="content" style={{ padding: '25px 15px' }}>
            {detailsChildren}
          </div>
          {actionsChildren && (
            <div style={{ backgroundColor: 'white', padding: '16px', textAlign: 'right', justifyItems: 'flex-end' }}>
              {actionsChildren}
            </div>
          )}
        </section>
      </article>
    </div>
  );
};

export default TFFAccordionNew;
