import { TFF } from '@tff/types';

export const searchIsSameAsOriginalFlight = (
  tripParams: string[],
  Segment: TFF.Segment,
  bookingSource: string,
): boolean => {
  if (bookingSource === 'TUI-NDC') {
    return false;
  } else {
    if (tripParams === undefined) {
      return false;
    }
    const [date, ondFirst, ondSecond] = tripParams.slice(0, 3);
    const departureDate = Segment.DepartureTime.split('T')[0];
    const [segmentOndFirst, segmentOndSecond] = Segment.Ond.split('-');

    return date === departureDate && ondFirst === segmentOndFirst && ondSecond === segmentOndSecond;
  }
};
