import React from 'react';
import { useEffect, useContext, useState } from 'react';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { apigeeEndpoint } from '../apis';
import { AmendBookingRequest, AmendBookingResponse } from '@tff/types/TFF/amend-booking-service';

export type TypeOfStatus = 'IN_VALIDATING' | 'SUCCESS' | 'FAILURE';

export type UseAmendResponse = {
  amendResponse?: AmendBookingResponse;
  amendStatus?: TypeOfStatus;
  amendError?: Error | undefined;
  setAmendBookingRequest: (amendBookingRequest: AmendBookingRequest) => void;
  amendBookingRequest: AmendBookingRequest;
};

export const useAmendBooking = (): UseAmendResponse => {
  const [amendStatus, setAmendStatus] = React.useState<TypeOfStatus>();
  const [amendResponse, setAmendResponse] = React.useState<AmendBookingResponse>();
  const [amendError, setAmendError] = React.useState<Error | undefined>();
  const [amendBookingRequest, setAmendBookingRequest] = useState<AmendBookingRequest>({} as AmendBookingRequest);
  const { showLoading, closeLoading } = useContext(LoadingContext);

  useEffect(() => {
    const amendBookingFromBackend = async (amendBookingRequest: AmendBookingRequest): Promise<void> => {
      try {
        setAmendStatus('IN_VALIDATING');

        switch (amendBookingRequest.action) {
          case 'search-alternatives':
            showLoading('Searching Alternative Flights');
            break;
          case 'confirm':
            showLoading(`Confirming ${amendBookingRequest.bookingSource} Booking`);
            break;
          default:
            showLoading(`Validating ${amendBookingRequest.bookingSource} Booking`);
        }

        const response = await apigeeEndpoint.post('/amend-booking', amendBookingRequest);

        if (response.data?.status === 'SUCCESS') {
          setAmendStatus('SUCCESS');
          setAmendResponse(response.data.data ?? response.data);
        } else {
          const errorResponse = response.data;
          if (errorResponse && errorResponse.error) {
            setAmendError(errorResponse.error);
          }
          setAmendStatus('FAILURE');
        }
      } catch (error) {
        setAmendStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (
      (amendBookingRequest && amendBookingRequest.recordLocator) ||
      (amendBookingRequest && amendBookingRequest.sessionId)
    ) {
      void amendBookingFromBackend(amendBookingRequest);
    }
  }, [amendBookingRequest]);

  return { setAmendBookingRequest, amendError, amendResponse, amendStatus, amendBookingRequest };
};
