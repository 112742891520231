import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import { TypeOfSourceGroup } from '../../models';
import ContactsEditForm from './ContactsEditForm';
import AlertMessage from '../AlertMessage';
import { mapTitlesFromSource } from '../../util/mapTitles';
import AddressTile from './AddressTile';
import TFFIcon from '../../styled-components/TFFIcon';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFAccordionNew from '../../styled-components/TFFAccordionNew';
import TFFButton from '../../styled-components/TFFButton';

interface props {
  sourceGroup: TypeOfSourceGroup;
  midocoDetails: TFF.FlightDetails;
  addresses: TFF.Address[];
  handleEditBtn?: () => void;
  allowEdit?: boolean;
  midocoOrderNo: number | undefined;
  flightDetails: TFF.FlightDetails;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: '5px',
      margin: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
      border: '1px solid #BABABA',
      boxShadow: 'none',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 0px',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100px',
      width: '100%',
      backgroundColor: '#E2F3FE',
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
    },
    cardsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
    },
    cardSeparator: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }),
);

const ContactDetailCard: React.FC<props> = ({
  addresses,
  sourceGroup,
  midocoDetails,
  allowEdit,
  midocoOrderNo,
  flightDetails,
}) => {
  const midocoAddresses =
    midocoDetails?.OrderDetails?.Addresses?.map(mAdr => {
      mAdr.Salutation = mapTitlesFromSource(mAdr.Salutation);
      return mAdr;
    }) ?? [];

  const passengerEditEnabled =
    allowEdit !== false && midocoAddresses.filter(adr => adr.Type === 'INVOICE_ADDRESS').length > 0;
  const classes = useStyles();

  const [showEditForms, setShowEditForms] = useState<boolean>(false);
  const [untouchedAddresses, setUntouchedAddresses] = useState<TFF.Address[]>([
    ...addresses.map(adr => {
      adr.Salutation = mapTitlesFromSource(adr.Salutation);
      return adr;
    }),
  ]);
  const [showSuccess, setShowSuccess] = useState<boolean | string>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const intl = useIntl();

  const resetMessages = (): void => {
    setShowSuccess(false);
    setShowError(false);
  };

  const updateAddresses = (responseData, l) => {
    //console.log(responseData);
    try {
      //NDC no update required
      if (responseData.airlineDetails === 'No update at airline required') {
        setShowSuccess(intl.formatMessage({ id: 'edit.address.NDCSuccess' }));
      } else {
        //NDC returns an object, nsk an array?
        const addressesFromResponse =
          responseData.addresses ?? Object.values(responseData.airlineDetails?.OrderDetails?.Addresses);
        setUntouchedAddresses(addressesFromResponse);
        setShowSuccess(true);
      }

      //todo: midoco address should be updated from response not from local copy...
      midocoDetails.OrderDetails.Addresses = [
        ...(midocoDetails.OrderDetails.Addresses
          ? midocoDetails.OrderDetails.Addresses.filter(adr => adr.Type !== 'INVOICE_ADDRESS')
          : []),
        l,
      ];
    } catch (er) {
      console.error(er);
      setShowError(true);
    }

    setShowEditForms(false);
  };

  const renderHeader = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <span>
          <TFFIcon iconName="telephone-receiver" color="color-theme-variant" />
        </span>
        <TFFTypography
          text={intl.formatMessage({ id: 'contactDetails.title' }).toUpperCase()}
          fontWeight="bold"
          variant="lead"
        />
      </div>
    );
  };

  return (
    untouchedAddresses && (
      <TFFAccordionNew
        accordionId="contactDetails"
        summaryChildren={renderHeader()}
        detailsChildren={
          !showEditForms ? (
            <div className={classes.cardsWrapper}>
              {untouchedAddresses.map((a, index) => (
                <AddressTile key={index} address={a} headline={intl.formatMessage({ id: 'contacts.contact' })} />
              ))}

              {midocoAddresses
                ?.filter(a => a.Type === 'INVOICE_ADDRESS')
                .map((a, index) => (
                  <AddressTile
                    key={index}
                    address={a}
                    headline={'Midoco ' + intl.formatMessage({ id: 'contacts.contact' })}
                  />
                ))}
            </div>
          ) : (
            <ContactsEditForm
              flightDetails={flightDetails}
              midocoOrderNo={midocoOrderNo}
              sourceGroup={sourceGroup}
              midocoDetails={midocoDetails}
              onSuccess={(r, l) => updateAddresses(r, l)}
              onBack={() => {
                setShowEditForms(false);
                resetMessages();
              }}
            />
          )
        }
        actionsChildren={
          <>
            {showSuccess && (
              <AlertMessage
                message={{
                  type: 'success',
                  msg: showSuccess === true ? intl.formatMessage({ id: 'edit.passenger.success' }) : showSuccess,
                }}
              />
            )}
            {showError && (
              <AlertMessage
                message={{ type: 'error', msg: intl.formatMessage({ id: 'edit.passenger.unexpectedResponse' }) }}
              />
            )}
            {passengerEditEnabled && !showEditForms && (
              <TFFButton
                buttonText={intl.formatMessage({ id: 'edit.address.editAddresses' })}
                handleClick={() => {
                  setShowEditForms(true);
                  resetMessages();
                }}
                variant="secondary"
              />
            )}
          </>
        }
      />
    )
  );
};
export default ContactDetailCard;
