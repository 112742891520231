import * as React from 'react';
import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import { SeatIcon } from '../icons';
import AddIcon from '@mui/icons-material/Add';
import TFFIconButton from '../../styled-components/TFFIconButton';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFIcon from '../../styled-components/TFFIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    seatsPriceWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: '#E2F3FE',
      alignItems: 'center',
      padding: '5px 20px',
      borderRadius: '0 0 8px 8px',
    },
    priceWrapper: {
      display: 'flex',
      gap: '8px',
    },
    seatsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    tffSeats: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginLeft: '32px',
      gap: '8px',
    },
  }),
);

interface FlightSeatsAndPriceProps {
  ancillaries: TFF.Ancillary[];
  journey: TFF.Journey;
  orderDetails: TFF.OrderDetails;
  bookingSource: string;
  setOpenSeats: (value: boolean) => void;
}

const FlightSeatsAndPrice: React.FC<FlightSeatsAndPriceProps> = ({
  ancillaries,
  journey,
  bookingSource,
  orderDetails,
  setOpenSeats,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });

  const journeySeats = ancillaries
    .filter((a: TFF.Ancillary) => a.JourneyOnd === journey.Ond && a.Type === 'SEAT')
    .map(seat => (seat.Value ? seat.Value : 'x'))
    .join(', ');

  const fares: TFF.Fare[] | undefined = orderDetails?.Fares ? Object.values<TFF.Fare>(orderDetails.Fares) : [];

  const journeyFares: TFF.FareProduct[] = [];

  fares.forEach(f => {
    f?.FareProducts?.forEach(fp => {
      if (fp.JourneyOnd === journey.Ond) journeyFares.push(fp);
    });
  });

  const renderFlightPrice = () => {
    if (journeyFares && bookingSource === 'TUI-NSK') {
      let totalPrice = 0;
      journeyFares.forEach(j => {
        totalPrice += j?.BaseAmount as number;
      });
      return (
        <div className={classes.priceWrapper}>
          <TFFTypography
            text={intl.formatMessage({ id: 'cancel.flightPrice' })}
            variant="lead"
            modifier="uppercase"
            fontWeight="bold"
          />
          <TFFTypography
            text={`€ ${numberFormatter.format(+totalPrice)}`}
            variant="lead"
            modifier="uppercase"
            fontWeight="bold"
          />
        </div>
      );
    }
    return <></>;
  };

  return <div className={classes.seatsPriceWrapper}>{renderFlightPrice()}</div>;
};

export default FlightSeatsAndPrice;
