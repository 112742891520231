import React from 'react';
import FlightColumn from './FlightColumn';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import { BaggageIcon } from '../icons';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFAccordionNew from '../../styled-components/TFFAccordionNew';
import TFFButton from '../../styled-components/TFFButton';

interface props {
  flightDetails: TFF.FlightDetails;
  handleEdit: () => void;
  recordLocator: string;
  tfmPnr?: string;
  midocoOrderNo?: number;
}

const AncillariesCard: React.FC<props> = ({ flightDetails, handleEdit, recordLocator, tfmPnr, midocoOrderNo }) => {
  const intl = useIntl();
  const outboundFlight = flightDetails.OrderDetails.Journeys[0]?.Ond! || '';
  const returnFlight = flightDetails.OrderDetails.Journeys[1]?.Ond! || '';
  const passengers = flightDetails.OrderDetails.Passengers;
  const ancillaries: TFF.Ancillaries = flightDetails.OrderDetails.Ancillaries!;
  const isRoundTrip = flightDetails.OrderDetails.Journeys.length > 1;

  return (
    <TFFAccordionNew
      accordionId="ancillaries"
      summaryChildren={
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <BaggageIcon />
            <TFFTypography variant="lead" fontWeight="bold" text={intl.formatMessage({ id: 'ancillaries.title' })} />
          </div>

          <TFFTypography
            fontWeight="bold"
            variant="lead"
            text={intl.formatNumber(flightDetails.OrderSummary?.TotalAncillaryPrice!, {
              style: 'currency',
              currency: 'EUR',
            })}
          />
        </div>
      }
      detailsChildren={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'white',
            borderRadius: '4px',
            minHeight: '91px',
            width: '100%',
            padding: '30px',
            gap: '180px',
          }}
        >
          {outboundFlight! && (
            <FlightColumn
              passengers={passengers}
              flightOnd={outboundFlight}
              isOutbound={true}
              ancillaries={ancillaries}
              orderDetails={flightDetails.OrderDetails}
              journey={flightDetails.OrderDetails.Journeys[0]}
              recordLocator={recordLocator}
              tfmPnr={tfmPnr}
              midocoOrderNo={midocoOrderNo}
            />
          )}
          {isRoundTrip && (
            <FlightColumn
              passengers={passengers}
              flightOnd={returnFlight}
              isOutbound={false}
              ancillaries={ancillaries}
              orderDetails={flightDetails.OrderDetails}
              journey={flightDetails.OrderDetails.Journeys[1]}
              recordLocator={recordLocator}
              tfmPnr={tfmPnr}
              midocoOrderNo={midocoOrderNo}
            />
          )}
        </div>
      }
      actionsChildren={
        <TFFButton
          buttonText={`${intl.formatMessage({ id: 'ancillaries.edit' })}`}
          variant="secondary"
          handleClick={handleEdit}
        />
      }
    />
  );
};

export default AncillariesCard;
