import * as React from 'react';
import { useState, SetStateAction, useEffect } from 'react';
import { useRebook } from '../RebookContext';
import * as TFF from '@tff/types/TFF';
import Header from '../RebookFlightCard/Header';
import Title from '../RebookFlightCard/Title';
import SearchAlternatives from '../RebookFlightCard/SearchAlternatives';
import ChangeFlightCard from '../RebookFlightCard/ChangeFlightCard';
import { AtcomDialog } from '../RebookFlightCard/AtcomDialog';
import { Ancillary } from '@tff/types/TFF';
import { TypeOfStatus } from '../../../hooks/use-amend-booking';
import { AmendBookingRequest } from '@tff/types/TFF/amend-booking-service';
import TFFAlert from '../../../styled-components/TFFAlert';
import { Box } from '@mui/material';

export type SelectedAmendBookings = {
  combiHashCodes: string[];
  journeyONDs: string[];
  offerPrices: string[];
};

interface props {
  orderDetails: TFF.OrderDetails;
  journeys: TFF.Journey[];
  airports: TFF.IAirport[];
  recordLocator: string;
  selectedAmendBookings: SelectedAmendBookings;
  setSelectedAmendBookings: (value: SetStateAction<SelectedAmendBookings>) => void;
  tfmPnr: string | undefined;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  flightDetails: TFF.FlightDetails;
  midocoOrderNo?: number;
  cancelHandler: () => void;
  retrieveDb?: TFF.Retrieve;
  searchFinished: () => void;
  setAmendBookingRequest: (request: AmendBookingRequest) => void;
  amendResponse?: TFF.AmendBookingResponse;
  amendStatus?: TypeOfStatus;
  amendError?: Error | undefined;
}
const SearchAlternativeFlights: React.FC<props> = ({
  journeys,
  orderDetails,
  airports,
  recordLocator,
  rebookCloser,
  flightDetails,
  midocoOrderNo,
  cancelHandler,
  retrieveDb,
  searchFinished,
  setAmendBookingRequest,
  amendResponse,
  amendStatus,
  amendError,
}) => {
  const { outboundFlights, returnFlights, enableSearchAlternatives, toggleSearchAlternatives, rebookSearchState } =
    useRebook();

  const [searchResults, setSearchResults] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [searchAlternativeFlights, setSearchAlternativeFlights] = useState<boolean>(false);

  const checkNonAtcomAncillaries = (): boolean => {
    const ancillariesList: Ancillary[] = Object.values(flightDetails.OrderDetails.Ancillaries!);
    return ancillariesList.filter(a => a.Type !== 'SEAT').some(a => !a.Note || a.Note !== 'ATCOM');
  };

  const handleRequest = async (dialog?: boolean) => {
    const firstJourney = orderDetails.Journeys[0];
    const outboundSearchFields = rebookSearchState['Outbound'][0];
    const [outboundDeparture, outboundOrigin, outboundDestination] = outboundSearchFields;

    let flightDetailsRequest = [
      {
        Origin: outboundOrigin,
        Destination: outboundDestination,
        DepDate: outboundDeparture,
        CabinProduct: outboundSearchFields[outboundSearchFields.length - 1].toUpperCase(), //TODO - we should improve this
      },
    ];

    let journeyOndsRequest = [firstJourney.Ond!];

    const hasTwoJourneys = orderDetails.Journeys.length > 1;

    if (hasTwoJourneys) {
      const lastJourney = orderDetails.Journeys[orderDetails.Journeys.length - 1];
      const inboundSearchFields = rebookSearchState['Return'][0] ?? '';
      const [inboundDeparture, inboundOrigin, inboundDestination] = inboundSearchFields;

      flightDetailsRequest.push({
        Origin: inboundOrigin,
        Destination: inboundDestination,
        DepDate: inboundDeparture,
        CabinProduct: inboundSearchFields[inboundSearchFields.length - 1].toUpperCase(), //TODO - we should improve this
      });

      journeyOndsRequest.push(lastJourney.Ond!);
    }

    const searchRequest: AmendBookingRequest = {
      action: 'search-alternatives',
      // @ts-ignore
      bookingSource: `TUI-NDC-${retrieveDb?.bookingSource}`,
      recordLocator: recordLocator,
      tfmPnr: retrieveDb?.tfmPnr!,
      midocoOrderNo: midocoOrderNo,
      flightDetails: flightDetailsRequest,
      journeyONDs: journeyOndsRequest,
    };

    setAmendBookingRequest(searchRequest);
    setSearchAlternativeFlights(true);
  };

  useEffect(() => {
    if (searchAlternativeFlights && amendStatus !== 'IN_VALIDATING' && (amendResponse || amendStatus === 'FAILURE')) {
      if (amendStatus === 'FAILURE') {
        // We should know what to do if the request presents a failure
        setShowWarning(true);
      } else {
        setShowWarning(false);
        searchFinished();
      }
    }
  }, [amendStatus]);

  useEffect(() => {
    if (outboundFlights.length == 0 && returnFlights.length == 0 && enableSearchAlternatives) {
      setSearchResults(true);
    } else {
      setSearchResults(false);
    }
  }, [outboundFlights, returnFlights]);

  return (
    <>
      {journeys.map((j: TFF.Journey, index) => {
        const isFirstJourney = index === 0;
        return (
          <React.Fragment key={j.JourneyIdentifier}>
            {isFirstJourney && (
              <>
                {showWarning && <TFFAlert variant="warning" text={`${amendError}`} extraStyles={{ width: '100%' }} />}
                <Title orderDetails={orderDetails} recordLocator={recordLocator} />
              </>
            )}
            <Header isFirstJourney={isFirstJourney} />
            <ChangeFlightCard
              journey={j}
              airports={airports}
              orderDetails={orderDetails}
              retrieveDb={retrieveDb}
              setEnableSearchAlternatives={toggleSearchAlternatives}
            />
          </React.Fragment>
        );
      })}
      <SearchAlternatives
        handleRequest={handleRequest}
        rebookCloser={rebookCloser}
        dataChanged
        cancelHandler={cancelHandler}
      />
    </>
  );
};

export default SearchAlternativeFlights;
