import React from 'react';
import { PriceClasses, Segment } from '@tff/types/TFF';
import TFFIcon from '../../../../styled-components/TFFIcon';
import TFFTypography from '../../../../styled-components/TFFTypography';

interface FarePerSegmentProps {
  segments: Segment[];
  prices: PriceClasses;
  isOutbound?: boolean;
}

const FarePerSegment: React.FC<FarePerSegmentProps> = ({ segments, prices, isOutbound = false }) => {
  return (
    <>
      {segments.length > 0 && (
        <div style={{ display: 'flex', gap: '8px' }}>
          <div>
            <span>
              <TFFIcon iconName={isOutbound ? 'aircraft-right' : 'aircraft-left'} color="color-theme-variant" />
            </span>
          </div>
          <div style={{ marginBottom: '8px' }}>
            {segments.map((segment, index) => {
              const segmentId = segment.Id;

              return (
                <div style={{ display: 'flex', gap: '8px', marginBottom: '4px' }}>
                  <TFFTypography text={segment.Ond} color="color-functional-neutral-900" />
                  <TFFTypography
                    text={prices.Segments?.[segmentId].Name}
                    color="color-functional-neutral-900"
                    fontWeight="bold"
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default FarePerSegment;
