import React, { useEffect, useState } from 'react';
import FlightStopsPath from '../FlightStopsPath';
import { AmendJourney } from '@tff/types/TFF';
import TFFAccordion from '../../../../styled-components/TFFAccordion';
import { useIntl } from 'react-intl';
import AlternativeFlightDetails from './AlternativeFlightDetails';
import { CombinationOffer } from '@tff/types/TFF/tff-flight-details';

interface props {
  journey: AmendJourney;
  isOutbound?: boolean;
  selectedOffer: CombinationOffer | undefined;
  fareOffers: CombinationOffer[];
}

const AlternativeFlightPerJourney: React.FC<props> = ({ journey, isOutbound, selectedOffer, fareOffers }) => {
  const intl = useIntl();

  const firstOffer = fareOffers[0];
  const [currentOffer, setCurrentOffer] = useState(firstOffer);

  useEffect(() => {
    if (selectedOffer) {
      const findFareOffer = fareOffers.find(offer => offer.Id === selectedOffer.Id);
      if (findFareOffer) {
        setCurrentOffer(selectedOffer);
      }
    }
  }, [selectedOffer]);

  return (
    <div
      style={{
        border: '1px solid var(--color-functional-neutral-400)',
        marginBottom: isOutbound ? '16px' : '0',
        backgroundColor: 'var(--color-functional-neutral-000)',
      }}
    >
      <div
        style={{
          padding: '24px 64px 0 24px',
        }}
      >
        <FlightStopsPath
          icon={isOutbound ? 'aircraft-right' : 'aircraft-left'}
          journey={journey}
          capitalizedCabinClass={currentOffer.PriceClasses.Journeys[journey.Id]}
          alignClassToRight
        />
      </div>
      <TFFAccordion
        text={intl.formatMessage({ id: 'pages.flightDetails.flightDetails' })}
        details={<AlternativeFlightDetails journey={journey} offer={currentOffer} />}
      />
    </div>
  );
};

export default AlternativeFlightPerJourney;
