import * as React from 'react';
import { PaxWithPhoneAndEmail } from './PassengersEditForm';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Theme, Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { PropsWithChildren } from 'react';
import { styled } from '@mui/styles';
import TFFTypography from '../../styled-components/TFFTypography';

interface CardProps {
  originalPassenger: PaxWithPhoneAndEmail;
  ticketNumber: string | null;
  associate: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accent: {
      color: theme.palette.error.main,
    },
    centered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cardRoot: {
      display: 'flex',
      backgroundColor: 'white',
      minHeight: '91px',
      padding: '16px',
      alignItems: 'flex-start',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
  }),
);

const MUIBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'white',
  borderRadius: '4px',
  minHeight: '91px',
  width: '100%',
}));

const CheckboxWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '25px',
  display: 'flex',
  alignItems: 'flex-start',
  margin: '18px 0px',
}));

const PassengerTile = (props: PropsWithChildren<CardProps>) => {
  const intl = useIntl();
  const getType = (type: string) => {
    switch (type) {
      case 'ADT':
        return intl.formatMessage({ id: 'passengerDetails.adult' });
      case 'INF':
        return intl.formatMessage({ id: 'passengerDetails.infant' });
      case 'CHD':
        return intl.formatMessage({ id: 'passengerDetails.child' });
      default:
        break;
    }
  };
  const { originalPassenger, ticketNumber } = props;
  const classes = useStyles();

  const bull = <strong className={classes.bullet}>•</strong>;
  const born = <span>{intl.formatMessage({ id: 'passengerDetails.DOB' })} </span>;

  const formatDate = (t: string): string | null => {
    if (t) {
      return format(new Date(t), 'dd.MM.yyyy');
    }

    return null;
  };

  const passengerDetails = (p: PaxWithPhoneAndEmail) => {
    return (
      <span>
        <div>
          {getType(p.Type)}
          {bull}
          {born}
          {p.DOB && formatDate(p.DOB)}
        </div>
        {ticketNumber && ticketNumber}
      </span>
    );
  };

  const showTitle = paxType => {
    //don't show title for children and infants
    return paxType !== 'INF' && paxType !== 'CHD';
  };

  return (
    <MUIBox>
      {props.children && <CheckboxWrapper>{props.children}</CheckboxWrapper>}
      <Card className={classes.cardRoot} variant="elevation" elevation={0}>
        <CardHeader
          title={
            <TFFTypography
              text={`${
                showTitle(originalPassenger.Type) ? `${originalPassenger.Title} ` : ''
              } ${`${originalPassenger.FirstName} ${originalPassenger.LastName}`}`}
            />
          }
          subheader={
            <>
              <span style={{ fontSize: '16px' }}>{passengerDetails(originalPassenger)}</span>
              <br />
              {props.associate && <span>{`Associate to ${props.associate}`}</span>}
            </>
          }
          sx={{ marginLeft: '6px', padding: 0 }}
        />
      </Card>
    </MUIBox>
  );
};

export default React.memo(PassengerTile);
