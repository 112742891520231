import React from 'react';

interface TFFDividerProps {
  type?: 'horizontal' | 'vertical' | 'title';
  size?: 'thin' | 'medium' | 'thick';
}

export const TFFDivider: React.FC<TFFDividerProps> = ({ type = 'horizontal', size = 'medium' }) => {
  return (
    <hr
      className={`divider ${size} ${type}`}
      style={{ margin: '8px 0', color: 'var(--color-achromatic-200)' }}
      aria-hidden={true}
    ></hr>
  );
};

export default TFFDivider;
