import React from 'react';
import { AmendJourney } from '@tff/types/TFF';
import { NdcFlightPath } from '../NdcFlightPath';
import { CombinationOffer } from '@tff/types/TFF/tff-flight-details';

interface AlternativeFlightDetailsProps {
  journey: AmendJourney;
  offer: CombinationOffer;
}

const AlternativeFlightDetails: React.FC<AlternativeFlightDetailsProps> = ({ journey, offer }) => {
  return (
    <div style={{ padding: '16px' }}>
      {journey?.Segments.map((seg, index) => (
        <NdcFlightPath
          segment={seg}
          index={index}
          key={index}
          stops={journey.Stops}
          /* @ts-ignore */
          stopDuration={journey.StayDuration}
          fareOrClass={offer.PriceClasses.Segments[seg.Id].Name}
        />
      ))}
    </div>
  );
};

export default AlternativeFlightDetails;
