import React from 'react';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFIcon from '../../styled-components/TFFIcon';

interface props {
  ancillary: TFF.Ancillary;
  isOutbound: boolean;
}

const AncillaryRow: React.FC<props> = ({ ancillary, isOutbound }) => {
  const intl = useIntl();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: isOutbound ? '340px' : '300px',
      }}
    >
      <TFFTypography
        text={
          <>
            {ancillary.Type === 'SEAT' && (
              <span className="tff-cabin-class-icon">
                <TFFIcon iconName="seat" />
              </span>
            )}
            {ancillary.TfaDetails !== undefined && ancillary.Type !== 'SEAT'
              ? intl.locale === 'en-US'
                ? ancillary.TfaDetails?.DescriptionEN
                : ancillary.TfaDetails?.DescriptionDE
              : ancillary.Type === 'SEAT'
              ? ancillary.Value
              : ancillary.Description ?? ''}
          </>
        }
        extraStyle={{
          display: 'flex',
        }}
        color={
          ancillary.Note === 'ATCOM' || ancillary.Type === 'SEAT' || ancillary.Type === 'INF'
            ? 'color-functional-neutral-900'
            : 'color-functional-neutral-400'
        }
      />
      <TFFTypography size="small" text={`${ancillary.TotalAmount!.toFixed(2)} €`} />
    </div>
  );
};

export default AncillaryRow;
