import * as React from 'react';
import TFFTypography from './TFFTypography';

interface TFFRadioInputProps {
  inputId: string;
  labelText: string;
  handleClick: () => void;
  checked?: boolean;
}

export const TFFRadioInput: React.FC<TFFRadioInputProps> = ({ inputId, labelText, handleClick, checked = false }) => {
  return (
    <label key={`${inputId}`} htmlFor={`${inputId}`} className="input input-radio">
      <span className="group">
        <input id={`${inputId}`} name={inputId} type="radio" onChange={handleClick} checked={checked} />
        <span className="svg-radio">
          <svg className="svg" width="24px" height="24px" viewBox="0 0 24 24">
            <circle className="fill" cx="12" cy="12" r="11"></circle>
            <circle className="stroke" cx="12" cy="12" r="11"></circle>
          </svg>
        </span>
        <span className="label-inline">
          <TFFTypography text={labelText} color="color-functional-neutral-900" />
        </span>
      </span>
    </label>
  );
};

export default TFFRadioInput;
