import { TFF } from '@tff/types';

const removeSsrFromOriginalSsrs = (includedSsrsForPax: TFF.SsrDetails[], ssr: TFF.SsrDetails) => {
  if (
    includedSsrsForPax?.find(
      (includedSsr: TFF.SsrDetails) => includedSsr.code === ssr.code && includedSsr.segmentOnd === ssr.segmentOnd,
    )
  ) {
    return {
      ...ssr,
      code: ssr.code + '-remove',
    };
  }
};

const swapBaggage = (
  paxId: string,
  newBaggage: TFF.SsrDetails,
  setSsrsToAdd: (arg0: (prevState: any) => any) => void,
  includedSsrs: { [x: string]: any[] },
) => {
  setSsrsToAdd(prevState => {
    const newSsrs = prevState[paxId].reduce((acc: TFF.SsrDetails[], ssr: TFF.SsrDetails) => {
      if (ssr.segmentOnd === newBaggage.segmentOnd) {
        //Baggage case ('BA')
        if (ssr.code.startsWith('BA')) {
          if (ssr.code.includes('remove')) {
            if (newBaggage.code === ssr.code.replace('-remove', '')) {
              return acc;
            } else {
              acc.push(ssr);
            }
          } else {
            acc.push(newBaggage);
          }
          const ssrToRemove = removeSsrFromOriginalSsrs(includedSsrs[paxId], ssr);
          if (ssrToRemove) {
            acc.push(ssrToRemove);
          }
        }
        //Additional Luggage case ('BX')
        else {
          if (newBaggage.code === 'BA0' && ssr.code.startsWith('BX')) {
            const ssrToRemove = removeSsrFromOriginalSsrs(includedSsrs[paxId], ssr);
            if (ssrToRemove) {
              acc.push(ssrToRemove);
            }
          } else {
            acc.push(ssr);
          }
        }
      } else {
        acc.push(ssr);
      }
      return acc;
    }, []);
    return {
      ...prevState,
      [paxId]: newSsrs,
    };
  });
};

export const addSsrsToAdd = (paxId: string, ssrToAdd: TFF.SsrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs) => {
  //Baggage case ('BA')
  if (ssrToAdd.code.startsWith('BA')) {
    // Confirm with Tolga if existingBaggage can be removed has it seems that it is always true - we'll always have a "BA" in ssrsToAdd
    const existingBaggage = ssrsToAdd[paxId]?.find(ssr => {
      return ssr.code.startsWith('BA') && ssr.segmentOnd === ssrToAdd.segmentOnd;
    });
    if (existingBaggage) {
      swapBaggage(paxId, ssrToAdd, setSsrsToAdd, includedSsrs);
      return;
    }
  }

  setSsrsToAdd(prevState => {
    const ssrWithSameCode = prevState[paxId].filter(
      ssr => ssr.code === ssrToAdd.code && ssr.segmentOnd === ssrToAdd.segmentOnd,
    );

    if (ssrWithSameCode.length > 0) {
      return {
        ...prevState,
        [paxId]: prevState[paxId].map(ssr => {
          if (ssr.code === ssrToAdd.code && ssr.segmentOnd === ssrToAdd.segmentOnd) {
            return { ...ssr, amount: ssr.amount + 1, tuiRelated: true };
          }
          return ssr;
        }),
      };
    } else {
      const ssrToRemoveWithSameCode = prevState[paxId].filter(
        ssr =>
          ssr.code.includes('remove') &&
          ssr.code.replace('-remove', '') === ssrToAdd.code &&
          ssr.segmentOnd === ssrToAdd.segmentOnd,
      );

      if (ssrToRemoveWithSameCode.length > 0) {
        return {
          ...prevState,
          [paxId]: prevState[paxId].map(ssr => {
            if (
              ssr.code.includes('remove') &&
              ssr.code.replace('-remove', '') === ssrToAdd.code &&
              ssr.segmentOnd === ssrToAdd.segmentOnd
            ) {
              return { ...ssr, code: ssrToAdd.code, amount: ssrToAdd.amount, tuiRelated: true };
            }
            return ssr;
          }),
        };
      }

      return {
        ...prevState,
        [paxId]: [...(prevState[paxId] || []), ssrToAdd],
      };
    }
  });
};
