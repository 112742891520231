import { Divider, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useMemo } from 'react';
import { TFF } from '@tff/types';
import { IFee } from '@tff/types/TFF';
import { useIntl } from 'react-intl';
import TFFTypography from '../styled-components/TFFTypography';
import TFFIcon from '../styled-components/TFFIcon';

interface props {
  flightDetails: TFF.FlightDetails;
  midocoFlightDetails?: TFF.FlightDetails;
  serviceFee?: IFee;
  serviceFeeTotalAmount?: number;
  servicePackageFee?: number;
  onDetailClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: '5px',
      margin: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
      border: '1px solid #BABABA',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100px',
      width: '100%',
      padding: '20px',
      backgroundColor: '#E2F3FE',
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
    },
    accountsRoot: {
      padding: '0px 40px 20px 40px',
    },
    accountTagRoot: {
      height: '71px',
      width: '247px',
      backgroundColor: '#70CBF4',
      borderRadius: '5px',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    flightsRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      backgroundColor: '#70CBF4',
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    flightDetails: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 10px 0px 30px',
    },
    seatsRoot: {
      padding: '10px',
      margin: '0px 20px',
    },
    upgradesRoot: {
      borderBottomWidth: '2px',
      borderBottomColor: theme.palette.text.primary,
      backgroundColor: '#70CBF4',
      borderBottomStyle: 'double',
      padding: '10px',
      margin: '5px 20px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    sumRoot: {
      padding: '10px',
      margin: '0px 20px',
    },
    balanceRoot: {
      backgroundColor: '#AAE0F9',
      padding: '10px',
      margin: '0px 20px 20px 20px',
    },
    paymentTableRoot: {
      margin: '20px',
    },
    table: {
      minWidth: 650,
    },
  }),
);

const PricingDetailCard: React.FC<props> = ({
  flightDetails,
  serviceFee,
  midocoFlightDetails,
  onDetailClick,
  serviceFeeTotalAmount,
  servicePackageFee,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });

  const orderDetails = flightDetails?.OrderDetails;
  const orderSummary = flightDetails?.OrderSummary;

  const taxes = useMemo(() => {
    let _taxes = 0;
    if (!orderDetails.Fares) {
      return _taxes;
    }
    const fares = Object.values(orderDetails.Fares);
    fares.forEach(f => {
      _taxes += f.TotalFeesAndTaxesAmountIncluded ?? 0;
      _taxes += f.TotalFeesAndTaxesAmountExcluded ?? 0;
    });
    return _taxes;
  }, [orderDetails]);

  const renderPricingIcon = () => {
    return (
      <div className={classes.iconWrapper}>
        <span>
          <TFFIcon iconName="pricetag" color="color-theme-variant" />
        </span>
      </div>
    );
  };

  /*
  const renderUpgrades = () => {
    return (
      <div className={classes.upgradesRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'cancel.change&cancellation' })}</strong>
          </Typography>
          <Typography variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            {!serviceFee ? (
              <strong>0</strong>
            ) : (
              <strong>
                {serviceFee?.flexibel ? 'max.' : 'Fix'} {serviceFee?.fee}
              </strong>
            )}{' '}
            p.P.
          </Typography>
        </div>
      </div>
    );
  };
  */
  const renderSum = () => {
    return (
      <div className={classes.sumRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <TFFTypography text={intl.formatMessage({ id: 'pricingDetails.flightPrice' })} />
          <TFFTypography text={numberFormatter.format((orderSummary?.TotalFarePrice ?? 0) - taxes)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <TFFTypography text={intl.formatMessage({ id: 'pricingDetails.taxAndFees' })} />
          <TFFTypography text={numberFormatter.format(taxes)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <TFFTypography text={intl.formatMessage({ id: 'pricingDetails.ancillaries' })} />
          <TFFTypography text={numberFormatter.format(orderSummary?.TotalAncillaryPrice ?? 0)} />
        </div>
        <Divider
          sx={{
            backgroundColor: '#BABABA',
            margin: '4px 0px',
            height: '2px',
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            <TFFTypography fontWeight="bold" text={intl.formatMessage({ id: 'pricingDetails.sum' })} />
            <span onClick={onDetailClick} style={{ cursor: 'pointer' }}>
              <TFFIcon color="color-theme-variant" iconName="link-external" />
            </span>
          </div>
          <TFFTypography
            fontWeight="bold"
            text={numberFormatter.format(
              (orderSummary?.TotalFarePrice ?? 0) + (orderSummary?.TotalAncillaryPrice ?? 0),
            )}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <TFFTypography text={intl.formatMessage({ id: 'pricingDetails.serviceFee' })} />
          <TFFTypography text={numberFormatter.format(serviceFeeTotalAmount ?? 0)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <TFFTypography text={intl.formatMessage({ id: 'pricingDetails.servicePackagesFee' })} />
          <TFFTypography text={numberFormatter.format(servicePackageFee ?? 0)} />
        </div>
      </div>
    );
  };

  const paymentsAmount = midocoFlightDetails?.OrderDetails?.Payments?.length
    ? Number(midocoFlightDetails?.OrderDetails?.Payments[0].Amount)
    : 0.0;

  const renderTotal = () => {
    const total =
      (orderSummary?.TotalFarePrice ?? 0) +
      (serviceFeeTotalAmount ?? 0) +
      (servicePackageFee ?? 0) +
      (orderSummary?.TotalAncillaryPrice ?? 0);

    return (
      <div>
        <div className={classes.sumRoot}>
          <Divider
            sx={{
              backgroundColor: '#BABABA',
              height: '2px',
              marginBottom: '10px',
              marginTop: '-15px',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <TFFTypography fontWeight="bold" text={intl.formatMessage({ id: 'pricingDetails.total' })} />
            <TFFTypography fontWeight="bold" text={numberFormatter.format(total)} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <TFFTypography text={intl.formatMessage({ id: 'pricingDetails.alreadyCharged' })} />
            <TFFTypography
              text={
                midocoFlightDetails?.OrderDetails?.Payments && midocoFlightDetails?.OrderDetails?.Payments.length
                  ? numberFormatter.format(midocoFlightDetails?.OrderDetails?.Payments[0].Amount)
                  : ''
              }
            />
          </div>{' '}
        </div>
        <div className={classes.balanceRoot}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <TFFTypography fontWeight="bold" text={intl.formatMessage({ id: 'pricingDetails.outstanding' })} />
            <TFFTypography
              fontWeight="bold"
              extraStyle={{ color: paymentsAmount - total < 0 ? 'red' : 'white' }}
              text={numberFormatter.format(paymentsAmount - total)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={classes.flexCenter}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {renderPricingIcon()}
          <TFFTypography
            text={intl.formatMessage({ id: 'pricingDetails.title' }).toUpperCase()}
            fontWeight="bold"
            variant="lead"
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {renderHeader()}
      {/*renderUpgrades()*/}
      {renderSum()}
      {renderTotal()}
    </div>
  );
};

export default PricingDetailCard;
