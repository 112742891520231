import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TFF } from '@tff/types';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFIcon from '../../styled-components/TFFIcon';

const useStyles = makeStyles(() =>
  createStyles({
    cardClass: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
      padding: '16px',
    },
    addressContent: {
      textOverflow: 'ellipsis',
      verticalAlign: 'top',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingLeft: 27,
    },
    nameWrapper: {
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      verticalAlign: 'bottom',
    },
    top: {
      paddingRight: '5px',
      verticalAlign: 'bottom',
    },
  }),
);

type AddressBlockProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  title?: string;
};

const AddressBlock = ({ icon, children, title }: AddressBlockProps) => {
  const classes = useStyles();
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', left: 0 }}>{icon}</div>
      <Typography title={title} className={classes.addressContent} component={'div'} color="textSecondary" noWrap>
        {children}
      </Typography>
    </div>
  );
};

interface CardProps {
  address: TFF.Address;
  headline: string;
}

const AddressTile = (props: CardProps) => {
  const { address, headline } = props;
  const classes = useStyles();

  return (
    <div className={classes.cardClass}>
      <TFFTypography
        text={
          <>
            <strong>{headline}:</strong> {`Type ${address.Type ?? '-'}`}
          </>
        }
        color="color-functional-neutral-800"
      />

      <table style={{ marginBottom: 10, marginTop: '8px' }}>
        <tbody>
          <tr>
            <td className={classes.top}>
              <TFFTypography text={address.Title || address.Salutation} size="small" modifier="nowrap" />
            </td>
            <td className={classes.nameWrapper} title={`${address.FirstName} ${address.LastName}`}>
              <TFFTypography
                text={`${address.FirstName} ${address.LastName}`}
                extraStyle={{ textOverflow: 'ellipsis' }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <Grid container spacing={2}>
        {address.Street && (
          <Grid item style={{ maxWidth: '100%' }}>
            <AddressBlock
              title={`${address.Street}\n${address.CountryCode}-${address.ZipCode} ${address.City}`}
              icon={
                <span>
                  <TFFIcon iconName="location-pin" color="color-theme-variant" />
                </span>
              }
            >
              {address.Street}
              <br />
              {address.CountryCode}-{address.ZipCode} {address.City}
            </AddressBlock>
          </Grid>
        )}
        {address.Mail && (
          <Grid item>
            <AddressBlock
              title={address.Mail}
              icon={
                <span>
                  <TFFIcon iconName="letter-at" color="color-theme-variant" />
                </span>
              }
            >
              {address.Mail}
            </AddressBlock>
          </Grid>
        )}
        {address.Telephone?.PhoneNumber && (
          <Grid item>
            <AddressBlock
              title={address.Telephone?.PhoneNumber}
              icon={
                <span>
                  <TFFIcon iconName="telephone-receiver" color="color-theme-variant" />
                </span>
              }
            >
              {address.Telephone?.CountryCode} {address.Telephone?.AreaCode} {address.Telephone?.PhoneNumber}
            </AddressBlock>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AddressTile;
