import { AmendBookingResponse } from '@tff/types/TFF';
import AlternativeFlightCard from './AlternativeFlightCard';
import React, { useState } from 'react';
import TFFTypography from '../../../../styled-components/TFFTypography';
import { CombinationOffer, OfferCombinations } from '@tff/types/TFF/tff-flight-details';

interface props {
  amendResponse?: AmendBookingResponse;
}

const AlternativeFlightCards: React.FC<props> = ({ amendResponse }) => {
  const offers: { [key: string]: OfferCombinations } = amendResponse?.flightAlternatives?.CombinationOffers!;
  const firstCombinationOfferId = Object.keys(offers)[0];
  const firstOffer = amendResponse?.flightAlternatives?.CombinationOffers?.[firstCombinationOfferId]?.Offers.sort(
    (a, b) => a.TotalAmount - b.TotalAmount,
  )[0];
  const [selectedOffer, setSelectedOffer] = useState<CombinationOffer | undefined>(firstOffer);

  //TODO - this is the case where the BE retrieves an failure response, in which case we will have a future ticket to tackle this
  if (!amendResponse) {
    return (
      <div>
        No alternative flights to show for now. Note: this is the case where the BE retrieves an failure response, in
        which case we will have a future ticket to tackle this
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ margin: '16px 0' }}>
        <TFFTypography
          text="Alternative Flight Results"
          modifier="uppercase"
          color="color-theme-variant"
          fontWeight="bold"
          size="large"
        />
      </div>
      {Object.keys(amendResponse?.flightAlternatives?.CombinationOffers!).map((combinationOfferKey, index) => {
        const outboundJourneyId = combinationOfferKey.split('-')[0];
        const outboundJourney = amendResponse?.flightAlternatives?.Journeys.find(
          journey => journey.Id === outboundJourneyId,
        );

        const inboundJourneyId = combinationOfferKey.split('-')[1];
        const inboundJourney = amendResponse?.flightAlternatives?.Journeys.find(
          journey => journey.Id === inboundJourneyId,
        );

        const fareOffers: CombinationOffer[] | undefined =
          amendResponse?.flightAlternatives?.CombinationOffers?.[combinationOfferKey]?.Offers;

        return outboundJourney ? (
          <AlternativeFlightCard
            key={combinationOfferKey}
            outboundJourney={outboundJourney}
            inboundJourney={inboundJourney}
            fareOffers={fareOffers?.sort((a, b) => a.TotalAmount - b.TotalAmount)}
            selectedOffer={selectedOffer}
            handleOfferClick={(offer: CombinationOffer) => setSelectedOffer(offer)}
          />
        ) : null;
      })}
    </div>
  );
};

export default AlternativeFlightCards;
