import * as React from 'react';
import { useState, SetStateAction } from 'react';

import * as TFF from '@tff/types/TFF';
import SearchAlternativeFlights from './SearchAlternativeFlights';
import AlternativeFlightsPage from './AlternativeFlightsPage';
import { useAmendBooking } from '../../../hooks/use-amend-booking';

export type SelectedAmendBookings = {
  combiHashCodes: string[];
  journeyONDs: string[];
  offerPrices: string[];
};

interface props {
  orderDetails: TFF.OrderDetails;
  airports: TFF.IAirport[];
  recordLocator: string;
  selectedAmendBookings: SelectedAmendBookings;
  setSelectedAmendBookings: (value: SetStateAction<SelectedAmendBookings>) => void;
  tfmPnr: string | undefined;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  flightDetails: TFF.FlightDetails;
  midocoOrderNo?: number;
  cancelHandler: () => void;
  retrieveDb?: TFF.Retrieve;
}

type rebookStep = 'Search' | 'Alternatives';

const NdcRebook: React.FC<props> = ({
  orderDetails,
  airports,
  recordLocator,
  selectedAmendBookings,
  setSelectedAmendBookings,
  tfmPnr,
  rebookCloser,
  flightDetails,
  midocoOrderNo,
  cancelHandler,
  retrieveDb,
}) => {
  const [rebookStep, setRebookStep] = useState<rebookStep>('Search');
  const { setAmendBookingRequest, amendResponse, amendStatus, amendError } = useAmendBooking();

  const handleFinishSearch = () => {
    setRebookStep('Alternatives');
  };

  const handleShowScreenByStep = () => {
    switch (rebookStep) {
      case 'Search':
        return (
          <SearchAlternativeFlights
            orderDetails={orderDetails}
            journeys={orderDetails.Journeys}
            airports={airports}
            recordLocator={recordLocator}
            selectedAmendBookings={selectedAmendBookings}
            setSelectedAmendBookings={setSelectedAmendBookings}
            tfmPnr={tfmPnr}
            rebookCloser={rebookCloser}
            flightDetails={flightDetails}
            midocoOrderNo={midocoOrderNo}
            cancelHandler={cancelHandler}
            retrieveDb={retrieveDb}
            searchFinished={handleFinishSearch}
            setAmendBookingRequest={setAmendBookingRequest}
            amendResponse={amendResponse}
            amendStatus={amendStatus}
            amendError={amendError}
          />
        );
      case 'Alternatives':
        return (
          <AlternativeFlightsPage
            journeys={orderDetails.Journeys}
            setRebookStep={setRebookStep}
            rebookCloser={rebookCloser}
            amendResponse={amendResponse}
          />
        );
    }
  };

  return <>{handleShowScreenByStep()}</>;
};

export default NdcRebook;
