import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import { AddSeats } from './AddSeats';
import TFFAlert from '../../../styled-components/TFFAlert';
import { ErrorOutline } from '@mui/icons-material';

interface props {
  displayFlights?: boolean;
  displayConfirmation?: boolean;
  orderDetails: TFF.OrderDetails;
  recordLocator: string;
  offerFlights?: TFF.Journey[];
  setAncillarySeats?: (value: any) => void;
  setSeatRows?: (value: any) => void;
  searchResults?: boolean;
}

const Title: React.FC<props> = ({
  displayFlights,
  displayConfirmation,
  orderDetails,
  offerFlights,
  recordLocator,
  setAncillarySeats,
  setSeatRows,
  searchResults,
}) => {
  const intl = useIntl();
  const [openSeats, setOpenSeats] = useState<boolean>(false);
  const orderDetailsJourneyIdentifiers = new Set(orderDetails.Journeys.map(journey => journey.JourneyIdentifier));

  return (
    <>
      {!displayFlights && !displayConfirmation && searchResults && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            backgroundColor: '#FFEEBD',
            padding: '16px',
            borderRadius: '4px',
            gap: '8px',
          }}
        >
          <Typography sx={{ marginLeft: -0.5 }}>
            <ErrorOutline />
          </Typography>
          <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
            No flight is offered with these parameters. Please try again.
          </Typography>
        </Box>
      )}
      {displayConfirmation && setAncillarySeats && setSeatRows && offerFlights && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              padding: 0,
            }}
          >
            <Typography color="primary" variant="h6" style={{ fontWeight: 'Bold', paddingBottom: 10 }}>
              {!displayFlights && !displayConfirmation
                ? intl.formatMessage({ id: 'menu.changeBooking' }).toUpperCase()
                : intl.formatMessage({ id: 'rebook.Confirm' }).toUpperCase()}
            </Typography>
            {!displayFlights && displayConfirmation && (
              <Button
                style={{
                  backgroundColor: '#092A5E',
                  color: 'white',
                  borderRadius: '3px',
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                onClick={() => setOpenSeats(true)}
              >
                ADD SEATS
              </Button>
            )}
          </Box>
          {openSeats && displayConfirmation && (
            <div>
              <AddSeats
                openSeats={openSeats}
                setOpenSeats={setOpenSeats}
                orderDetails={orderDetails}
                offerFlights={offerFlights.filter(
                  flight => !orderDetailsJourneyIdentifiers.has(flight.JourneyIdentifier),
                )}
                recordLocator={recordLocator}
                setAncillarySeats={setAncillarySeats}
                setSeatRows={setSeatRows}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Title;
